import { graphql } from "gatsby";
import SEO from "@components/seo";
import React from "react";
import { css } from "@emotion/react";
import { Section, Div, Container, RowsNine } from "@components/Markup";
import PostHeader from "@components/PostHeader";
import { renderModularContent } from "@components/renderBlogPost";
import PrevNextNav from "@components/PrevNextNav";
import {
  PostThumbnail,
  PostThumbnailDownload,
} from "@components/PostThumbnail";
import Share from "@components/Share";
import { find, includes } from "lodash";

function extractSubstring(string, prefix, suffix) {
  let s = string;
  var i = s.indexOf(prefix);
  if (i >= 0) {
    s = s.substring(i + prefix.length);
  } else {
    return "";
  }
  if (suffix) {
    i = s.indexOf(suffix);
    if (i >= 0) {
      s = s.substring(0, i);
    } else {
      return "";
    }
  }
  return s;
}

function deleteSubstring(string, prefix, suffix) {
  let s = string;

  const startIndex = s.indexOf(prefix);
  const prefixAndSuffixLength = prefix.length + suffix.length;
  const substringLength = extractSubstring(string, prefix, suffix).length;
  const endIndex = startIndex + prefixAndSuffixLength + substringLength;

  const test = s.replace(s.substring(startIndex, endIndex), "");

  return test;
}

const BlogPost = ({ data }) => {
  const post = data?.post;
  const nextPost = data?.nextPost;
  const prevPost = data?.prevPost;

  const {
    postTitle,
    postDate,
    featuredImage,
    seoTitle,
    seoImage,
    seoDescription,
    content,
    fragen,
    news,
    downloads,
    wordpressContentLegacy,
  } = post;

  const seoDisplayTitle = `${
    seoTitle || postTitle
  } | Hawlik Gerginski | Impulse`;
  const seoDisplayImage = seoImage
    ? seoImage.fluid.src
    : featuredImage
    ? featuredImage.fluid.src
    : null;
  const seoDisplayDescription =
    seoDescription ||
    (content && content.length > 0 ? content[0].text : wordpressContentLegacy);
  const downloadable = find(content, { __typename: "DatoCmsDownload" });
  const hasADownloadable = downloads && downloadable;

  let displayContent = wordpressContentLegacy;

  while (includes(displayContent, "<figure")) {
    displayContent = deleteSubstring(displayContent, "<figure", "/figure>");
  }
  return (
    <React.Fragment>
      <SEO
        title={seoDisplayTitle}
        image={seoDisplayImage}
        description={seoDisplayDescription}
      />
      <Section pt="lg" pb="lg">
        <Container>
          <RowsNine>
            <PostHeader
              fragen={fragen}
              news={news}
              downloads={downloads}
              postTitle={postTitle}
              postDate={postDate}
            />
            <Div
              css={css`
                position: relative;
              `}
            >
              <Share />
              {!hasADownloadable && (
                <PostThumbnail featuredImage={featuredImage} />
              )}
              {hasADownloadable && (
                <PostThumbnailDownload
                  featuredImage={featuredImage}
                  {...downloadable}
                />
              )}
            </Div>
            {wordpressContentLegacy && (
              <Div
                mt="md"
                mb="md"
                css={css`
                  strong {
                    display: block;
                    margin: 24px 0px 0px;
                  }
                  ul {
                    margin: 16px 0px;
                  }
                `}
                dangerouslySetInnerHTML={{ __html: displayContent }}
              />
            )}
            <Div
              mt="md"
              mb="md"
              css={css`
                .ejbBUw {
                  ${hasADownloadable && `display: none;`}
                }
              `}
            >
              {renderModularContent({ content })}
            </Div>
          </RowsNine>
        </Container>
        {prevPost && nextPost && (
          <PrevNextNav
            prev={{
              featuredImage: prevPost.featuredImage,
              name: prevPost.postTitle,
              url: `/blog/${prevPost.slug}`,
            }}
            next={{
              featuredImage: nextPost.featuredImage,
              name: nextPost.postTitle,
              url: `/blog/${nextPost.slug}`,
            }}
          />
        )}
      </Section>
    </React.Fragment>
  );
};

export default BlogPost;

export const query = graphql`
  query BlogPostPageQuery(
    $slug: String!
    $nextSlug: String!
    $prevSlug: String!
  ) {
    nextPost: datoCmsBlogPost(slug: { eq: $nextSlug }) {
      slug
      postTitle
      featuredImage {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
    }
    prevPost: datoCmsBlogPost(slug: { eq: $prevSlug }) {
      slug
      postTitle
      featuredImage {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
    }
    post: datoCmsBlogPost(slug: { eq: $slug }) {
      postTitle
      seoTitle
      seoDescription
      seoImage {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
      postDate
      fragen
      news
      downloads
      featuredImage {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
      content {
        ... on DatoCmsDownload {
          id
          document {
            url
          }
          documentName
        }
        ... on DatoCmsImage {
          id
          items {
            fluid {
              src
              srcSet
              aspectRatio
            }
          }
        }
        ... on DatoCmsQuote {
          id
          text
          __typename
        }
        ... on DatoCmsParagraph {
          id
          text
          __typename
        }
      }
      wordpressContentLegacy
    }
  }
`;
